<template>
  <div>
    <label
      class="bg-white cursor-pointer flex-1 border-2 border-gray-100 booking-option-shadow flex sm:flex-row flex-col rounded-xl"
      :class="{ 'border-pink-500': selected == option.id }">
      <div class="flex">
        <div class="flex-shrink-0 flex justify-end py-6 pl-6">
          <img :src="option.icon_url" class="h-8 w-8 lg:h-14 lg:w-14" alt="">
        </div>
        <div class="flex-1 py-6 px-6">
          <div class="flex items-center space-x-3">
            <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
            <span class="text-xs text-purple-50 font-semibold uppercase">
              {{ price.variation }}
            </span>
          </div>
          <p class="text-sm text-gray-500 mt-2">
            <!-- Whether you host a podcast, Youtube channel, or private Q&A for your executive team, use this option to book an interview with your dream guest. -->
            {{ option.description }}
          </p>
        </div>
      </div>

      <div
        class="w-full sm:w-28 flex-shrink-0 border-t sm:border-t-0 sm:border-l border-guestio-gray-50 flex items-center flex-row sm:flex-col justify-center py-4 sm:py-0">
        <span class="text-xl font-bold text-blue-800">
          <template v-if="price.price == 0">
            Free
          </template>
          <template v-else>
            ${{ price.price }}
          </template>
        </span>
        <span class="font-bold text-sm text-gray-500 mt-1">/{{ price.interview_duration }} min</span>
        <input type="radio" class="hidden" :value="option.id" v-model="selected">
      </div>
    </label>

    <div v-if="selected == option.id && option.prices.length > 1" class="my-4">
      <h4 class="font-bold text-lg text-blue-800">Pick your desired package</h4>

      <div class="flex flex-wrap mt-3">
        <label v-for="(slot, index) in option.prices" :key="`slot-${index}`"
          class="cursor-pointer border-2 border-gray-100 booking-option-shadow rounded-xl flex flex-col justify-center items-center px-3 py-3 ml-2 mb-2 first:ml-0"
          :class="{ 'border-pink-500': selectedPriceId == slot.id }">
          <span class="text-lg font-bold text-blue-800">
            <template v-if="slot.price == 0">
              Free
            </template>
            <template v-else>
              ${{ slot.price }}
            </template>
          </span>
          <span class="font-bold text-xs text-gray-500 mt-1">/{{ slot.interview_duration }} min</span>
          <input type="radio" class="hidden" :value="slot.id" v-model="selectedPriceId">
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { first } from 'lodash/array'

export default {
  props: {
    option: Object,
    value: Number,
  },

  data() {
    return {
      selected: this.value,
      selectedPriceId: null,
    }
  },

  computed: {
    price() {
      return first(this.option.prices)
    },

    selectedPrice() {
      return this.option.prices.find(price => price.id === this.selectedPriceId)
    }
  },

  watch: {
    value(value) {
      this.selected = value
    },

    selectedPrice(selectedPrice) {
      this.$emit('change', {
        option: this.selected,
        price: selectedPrice,
      })
    },

    selected(selected) {
      if (selected != this.option.id) {
        return
      }

      if (!this.selectedPriceId) {
        this.selectedPriceId = this.price.id
      }

      this.$emit('change', {
        option: selected,
        price: this.selectedPrice,
      })
    }
  },
}
</script>

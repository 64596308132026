<template>
  <label class="bg-white cursor-pointer flex-1 border-2 border-gray-100 booking-option-shadow flex sm:flex-row flex-col rounded-xl" :class="{ 'border-pink-500': selected == option.id }">
    <div class="flex">
      <div class="flex-shrink-0 flex justify-end py-6 pl-6">
        <img :src="option.icon_url" class="h-8 w-8 lg:h-14 lg:w-14" alt="">
      </div>
      <div class="flex-1 py-6 px-6">
        <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
        <p class="text-sm text-gray-500 mt-2">
          <!-- Virtual stages are here to stay! If you're wanting a guest for a board meeting, virtual conference, private dinner, or something else, this is the option for you. -->
          {{ option.description }}
        </p>
      </div>
    </div>

    <div class="w-full sm:w-28 flex-shrink-0 border-t sm:border-t-0 sm:border-l border-guestio-gray-50 flex items-center flex-row sm:flex-col justify-center py-4 sm:py-0">
      <span class="text-xl font-bold text-blue-800">${{ price.price }}</span>
      <input type="radio" class="hidden" :value="option.id" v-model="selected">
    </div>
  </label>
</template>

<script>
  import { first } from 'lodash/array'

  export default {
    props: {
      option: Object,
      value: Number,
    },

    data() {
      return {
        selected: this.value,
      }
    },

    computed: {
      price() {
        return first(this.option.prices)
      }
    },

    watch: {
      value(value) {
        this.selected = value
      },

      selected(selected) {
        if (selected != this.option.id) {
          return
        }

        this.$emit('change', {
          option: selected,
          price: this.price,
        })
      }
    },
  }
</script>
